<template>
    <div class="asset-departmental-assets-layout">
        <component
            v-bind:is="currentComponent"
            :storeData="storeData"
            @changeComp="changeComp"
            @setPageInitTabs = "setPageInitTabs"
        ></component>
    </div>
</template>

<script>
import List from './CalendarManagement/index.vue';
import lookCalendar from './CalendarManagement/LookCalendarlist.vue';

export default {
    name: "TeachingResearchSchoolCalendarManagementScrollWrapper",
    components: {
        List,
        lookCalendar,
    },
    data () {
        return {
            comList: ['List', 'lookCalendar'],
            idx: 0,
            storeData: {},
            type:0
        }
    },
    computed: {
        currentComponent () {
            return this.comList[this.idx];
        }
    },
    async created() {
        const { id } = this.$route.query
        if (id) {
            this.type = 1
            this.idx = 1
            this.storeData = Object.assign({}, this.$route.query)
            // 重新导航到当前路由，并覆盖查询参数
            await this.$router.replace({ path: this.$route.path })
            this.setGlobalPageType('detail')
        }
    },
    activated() {
        this.setGlobalPageType(this.type)
    },
    methods: {
        /**
         * @Description: 查看校历，返回
         * @DoWhat: 返回校历管理页面
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2022-11-11 10:34:26
         */
        changeComp({index, data}) {
            this.idx = index
            Object.assign(this.storeData, data)
            if(this.idx === 0){
                this.setGlobalPageType('list')
            }else{
                this.setGlobalPageType('detail')
            }
        },
         /**
          * @Description: 页面状态 list列表 detail二级页面(form)
          * @DoWhat:一级列表页面（list）显示头部导航面包屑，二级页面（detail）、表单（form）不显示头部导航面包屑
          * @UseScenarios:
          * @Attention:
          * @Author:
          * @Date: 2023-07-21 11:58:06
          */
          setGlobalPageType (type) {
            this.type = type
            this.$eventDispatch('setGlobalPageType', type)
         },
         setPageInitTabs(){
            this.$eventDispatch('setGlobalPageType', 'list')
            this.idx = 0
        }
    }
}
</script>
<style lang="scss" scoped>

</style>
